<template>
    <div class="card site_footer mb_24 editor_section">
        <div class="card_heading">
            <h4 class="heading2">Summary Report Content</h4>
        </div>
        <div class="row client_accordian">
            <div class="form-group">
                <label class="form-label">Office Equipment Heading</label>
                <ckeditor :editor="editor" v-validate="'required'" data-vv-as="office equipment heading" v-model="client_data.office_equipment_heading" :config="editorConfig"  name="office_equipment_heading" id="office_equipment_heading" @blur="onLostFocusEditor('addCientForm.office_equipment_heading')"></ckeditor>
                <small class="error text-danger" v-show="errors.has('addCientForm.office_equipment_heading')">
                    {{ errors.first("addCientForm.office_equipment_heading") }}
                </small>
            </div>
            <div class="form-group">
                <label class="form-label">Home Equipment Heading</label>
                <ckeditor :editor="editor" v-validate="'required'" data-vv-as="home equipment heading" v-model="client_data.home_equipment_heading" :config="editorConfig" name="home_equipment_heading" id="home_equipment_heading" @blur="onLostFocusEditor('addCientForm.home_equipment_heading')"></ckeditor>
                <small class="error text-danger" v-show="errors.has('addCientForm.home_equipment_heading')">
                    {{ errors.first("addCientForm.home_equipment_heading") }}
                </small>
            </div>
            <div class="form-group">
                <label class="form-label">Lab Equipment Heading</label>
                <ckeditor :editor="editor" v-validate="'required'" data-vv-as="lab equipment heading" v-model="client_data.lab_equipment_heading" :config="editorConfig" name="lab_equipment_heading" id="lab_equipment_heading" @blur="onLostFocusEditor('addCientForm.lab_equipment_heading')"></ckeditor>
                <small class="error text-danger" v-show="errors.has('addCientForm.lab_equipment_heading')">
                    {{ errors.first("addCientForm.lab_equipment_heading") }}
                </small>
            </div>
            <div class="form-group">
                <label class="form-label">WFH Saftey Message</label>
                <ckeditor :editor="editor" v-validate="'required'" data-vv-as="wfh saftey message" v-model="client_data.wfh_saftey_message" :config="editorConfig" name="wfh_saftey_message" id="wfh_saftey_message" @blur="onLostFocusEditor('addCientForm.wfh_saftey_message')"></ckeditor>
                <small class="error text-danger" v-show="errors.has('addCientForm.wfh_saftey_message')">
                    {{ errors.first("addCientForm.wfh_saftey_message") }}
                </small>
            </div>
            <div class="form-group">
                <label class="form-label">Summary Report Footer</label>
                <ckeditor :editor="editor" v-validate="'required'" data-vv-as="summary report footer" v-model="client_data.summary_report_footer" :config="editorConfig" name="summary_report_footer" id="summary_report_footer" @blur="onLostFocusEditor('addCientForm.summary_report_footer')"></ckeditor>
                <small class="error text-danger" v-show="errors.has('addCientForm.summary_report_footer')">
                    {{ errors.first("addCientForm.summary_report_footer") }}
                </small>
            </div>
            <div class="row">
                <div class="form-group">
                    <label for="" class="form-label" >Select the category to display <strong>"Do you need more help?"</strong> Content </label >
                    <v-select v-model="client_data.conditional_msg" label="name" name="conditional_msg" :reduce="(conditionalmsg) => conditionalmsg.id" :options="conditionalmsg" placeholder="Select Role" v-validate="'required'" data-vv-as="Condition of Message" />
                    <small class="error text-danger" v-show="errors.has('addCientForm.conditional_msg')" >
                    {{ errors.first("addCientForm.conditional_msg") }}
                    </small>
                </div>
            </div>
            <div class="row">
                <div class="form-group">
                  <label for="" class="form-label" >Modify <strong>"Do you need more help?"</strong> Content </label >
                    <ckeditor :editor="editor" v-validate="'required'" data-vv-as="message content" v-model="client_data.conditional_msg_content" :config="editorConfig" name="conditional_msg_content" id="conditional_msg_content" @blur="onLostFocusEditor('addCientForm.conditional_msg_content')"></ckeditor>
                  <small class="error text-danger" v-show="errors.has('addCientForm.conditional_msg_content')" >
                    {{ errors.first("addCientForm.conditional_msg_content") }}
                  </small>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ClassicEditor from "ckeditor5-build-classic-image";
export default {
    name: "ClientSummaryReportContent",
    props: ['client_data'],
    inject: ["parentValidator"],
    created() {
        this.$validator = this.parentValidator
    },
    data() {
        return {
            summaryReportInfo: this.$store.getters["ClientsConfig/summaryReportInfo"],
            editor: ClassicEditor,
            editorData:
                "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>",
            editorConfig: {
                // The configuration of the editor.
                toolbar: {
                    items: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "|",
                        "undo",
                        "redo",
                    ],
                },
                link: {
                    // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
                    addTargetToExternalLinks: true,
                }
            },
            conditionalmsg:[
                {
                    id: 0,
                    name: "All employees see this messaging",
                },{
                    id: 1,
                    name: "Only employees that report discomfort will see the messaging",
                }, {
                    id: 2,
                    name: "Only employees that report moderate or severe discomfort will see the messaging",
                },{
                    id: 3,
                    name: "Only employees that report severe discomfort will see the message.",
                },
            ],
        };
    },
    beforeMount() {
        if (this.$route.params.id == undefined) {      
            // Json functions are used so that original values doesnt get changed
            this.client_data.office_equipment_heading = JSON.parse(JSON.stringify(this.summaryReportInfo.office_equipment_heading));
            this.client_data.home_equipment_heading = JSON.parse(JSON.stringify(this.summaryReportInfo.home_equipment_heading));
            this.client_data.lab_equipment_heading = JSON.parse(JSON.stringify(this.summaryReportInfo.lab_equipment_heading));
            this.client_data.wfh_saftey_message = JSON.parse(JSON.stringify(this.summaryReportInfo.wfh_saftey_message));
            this.client_data.summary_report_footer = JSON.parse(JSON.stringify(this.summaryReportInfo.summary_report_footer));
            this.client_data.conditional_msg_content = JSON.parse(JSON.stringify(this.summaryReportInfo.conditional_msg_content));
        }
    },
    methods: {
        onLostFocusEditor(id){
            this.$parent.onLostFocusEditor(id);
        },
    }
};
</script>